import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiSearchLine, RiSparkling2Fill } from "@remixicon/react";
import { useChat } from "ai/react";
import { getChatIdentifier } from "@/lib/key";
import IconButton from "./IconButton";
import MagicSearchResult from "./MagicSearchResult";
import { MagicSearchBarFollowUp } from "./MagicSearchBarFollowUp";
import dictionary from "@/lib/dictionary";
import styles from "./MagicSearchBar.module.scss";
import MagicSearchResultSkeleton from "./magicSearchResultSkeleton";
import { baseUrl, getEndpoint } from "@/lib/url";

export const MagicSearchBar = ({
  isMagicSearchOpen,
  setIsMagicSearchOpen,
}: {
  isMagicSearchOpen: boolean;
  setIsMagicSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [chatIdentifier, setChatIdentifier] = useState<string>(
    getChatIdentifier()
  );
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const userAgent = window.navigator.userAgent;

  //Check if userAgent is mac to render ⌘ or ctrl
  const isMac = userAgent.indexOf("Mac") !== -1;

  const {
    messages,
    handleSubmit,
    input,
    handleInputChange,
    isLoading,
    setMessages,
    setInput,
  } = useChat({
    api: getEndpoint("/api/search", {
      "domain-identifier": import.meta.env.DEV ? "dev" : "[({identifier})]",
    }),
    body: {
      chatIdentifier,
    },
  });

  useEffect(() => {
    const chat = document.getElementById(chatIdentifier);
    if (chat) chat.scrollTo(0, chat.scrollHeight);
  }, [messages, chatIdentifier, isLoading]);

  const handleNewSearch = useCallback(() => {
    setMessages([]);
    setInput("");
    setChatIdentifier(getChatIdentifier());
    inputRef.current?.focus();
  }, [setMessages, setInput]);

  const closeMagicSearch = useCallback(() => {
    setMessages([]);
    setIsMagicSearchOpen(false);
  }, [setIsMagicSearchOpen, setMessages]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        handleNewSearch();
      }
      if (event.key === "Escape") {
        event.preventDefault();
        closeMagicSearch();
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsMagicSearchOpen(false);
      }
    };

    if (isMagicSearchOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isMagicSearchOpen,
    setIsMagicSearchOpen,
    closeMagicSearch,
    handleNewSearch,
  ]);

  useEffect(() => {
    if (isMagicSearchOpen) {
      inputRef.current?.focus();
    }
  }, [isMagicSearchOpen]);

  return (
    <>
      {isMagicSearchOpen ? (
        <div className={styles.modal} aria-modal={"true"}>
          <div ref={modalRef} className={styles.content}>
            <div className={styles.modalHeader}>
              <RiSparkling2Fill size={24} className={styles.sparklingIcon} />
              <form onSubmit={handleSubmit} className={styles.input}>
                <input
                  type="text"
                  ref={inputRef}
                  value={input}
                  onChange={handleInputChange}
                  disabled={isLoading}
                  className={styles.input}
                  placeholder={dictionary.magicSearchPlaceholder}
                />
              </form>

              <RiSearchLine size={24} />
            </div>
            {(messages && messages.length > 0) || isLoading ? (
              <div className={styles.modalBody} id={chatIdentifier}>
                <div className={styles.searchResultContainer}>
                  {messages.map((message, index, row) => {
                    if (message.role === "assistant") {
                      return (
                        <MagicSearchResult
                          key={message.id}
                          isLoading={isLoading}
                          message={message}
                          questionNumber={(index + 1) / 2}
                          originalQuestion={messages[index - 1].content ?? ""}
                          messages={messages}
                          setMessages={setMessages}
                        />
                      );
                    }

                    if (index + 1 === row.length) {
                      return (
                        <MagicSearchResultSkeleton
                          key={`search-skeleton-${index}`}
                          question={messages[messages.length - 1].content}
                          resultNumber={
                            index === 0 ? index + 1 : (index + 2) / 2
                          }
                        />
                      );
                    }
                  })}
                </div>

                {!isLoading ? (
                  <MagicSearchBarFollowUp
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    input={input}
                    isLoading={isLoading}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            {/* <div className={styles.modalBody} id={chatIdentifier}>
              <div className={styles.searchResultContainer}>
                <MagicSearchResultSkeleton question={"contact"} resultNumber={0} />
              </div>
            </div> */}
            <div className={styles.modalFooter}>
              <div className={styles.iconContainer}>
                <a
                  href="https://eloquent.chat/"
                  target="_blank"
                  className={styles.powered}
                >
                  Powered by
                  <img src={`${baseUrl}/icons/eloquent_text.svg`} alt="" />
                </a>
                <div className={styles.actionIcons}>
                  <IconButton
                    title={dictionary.closeMagicSearch}
                    onClick={closeMagicSearch}
                  >
                    <p>esc</p>
                  </IconButton>
                  <IconButton
                    title={dictionary.newMagicSearch}
                    onClick={handleNewSearch}
                  >
                    <p>{isMac ? "⌘K" : "Ctrl + K"}</p>
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
