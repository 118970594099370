import useDebounce from "@/hooks/useDebounce";
import dictionary from "@/lib/dictionary";
import { RiSearch2Line } from "@remixicon/react";
import { Ring } from "@uiball/loaders";
import ky from "ky";
import { useEffect, useRef, useState } from "react";
import Result, { IResult } from "./Result";
import { getEndpoint } from "@/lib/url";

const WebSearch = () => {
  const { debouncedValue, value, setValue } = useDebounce();

  const [results, setResults] = useState<IResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getResults = async () => {
    if (!value) return;

    setLoading(true);
    setShowModel(true);

    try {
      const data = await ky<{ embeddings: IResult[] }>(
        getEndpoint(`/api/web-search`, {
          identifier: import.meta.env.DEV ? "dev" : "[({identifier})]",
          query: value,
        })
      ).json();

      setError(null);
      setResults(data.embeddings.sort((a, b) => b.similarity - a.similarity));
    } catch (e) {
      setError(dictionary.errorMessage);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (debouncedValue) getResults();
  }, [debouncedValue]);

  useEffect(() => {
    if (results.length === 0 && !value) {
      setShowModel(false);
    }
  }, [value, results]);

  const handleInputFocus = () => {
    if (results.length > 0 && value) {
      setShowModel(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowModel(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="w-full flex flex-col items-center mt-10">
      <div ref={containerRef} className="relative w-fit">
        <div className="flex border w-fit items-center pr-3 rounded-lg overflow-hidden">
          <input
            ref={inputRef}
            className="p-3 focus:outline-none"
            onFocus={handleInputFocus}
            onChange={(e) => setValue(e.target.value)}
            value={value}
            placeholder={dictionary.askSomething}
          />
          <RiSearch2Line size={20} />
        </div>

        {(showModel || loading) && (
          <div className="absolute top-full w-[90dvw] max-w-xl translate-x-[-50%] left-[50%] bg-secondary border shadow-xl rounded-xl mt-2 p-4 h-[50dvh] overflow-auto">
            <div className="flex flex-col gap-2 h-full w-full">
              {loading && (
                <div className="w-full h-full left-0 top-0 absolute grid place-items-center bg-secondary/60">
                  <Ring />
                </div>
              )}
              {error && (
                <div className="text-red-500 text-center h-full grid place-items-center">
                  {error}
                </div>
              )}
              {results.length
                ? results
                    .filter((result) => result.title)
                    .map((result) => <Result key={result.id} result={result} />)
                : !loading &&
                  !error && (
                    <div className="text-muted text-center h-full grid place-items-center">
                      {dictionary.noResults}
                    </div>
                  )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WebSearch;
