import dictionary from "@/lib/dictionary";
import { shouldLogoFallback } from "@/lib/logo";
import { disabledPopupMessage } from "@/lib/popupMessage";
import { baseUrl } from "@/lib/url";
import { RiCloseFill } from "@remixicon/react";
import { useEffect, useState } from "react";
import styles from "./ChatTrigger.module.scss";

interface IProps {
  onOpenChange: (open: boolean) => void;
  chatOpened: boolean;
}

const ChatTrigger = ({ onOpenChange, chatOpened }: IProps) => {
  const onMobile = window.innerWidth < 500;
  const [popupMessageShown, setPopupMessageShown] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      if (window.sessionStorage.getItem("eloquent-message-shown") === "true")
        return;
      setPopupMessageShown(!disabledPopupMessage);
      window.sessionStorage.setItem("eloquent-message-shown", "true");
    }, 1000);

    return () => setPopupMessageShown(false);
  }, [disabledPopupMessage]);

  console.log(import.meta.env.DEV);

  console.log(shouldLogoFallback);

  return (
    <div className={styles.eloquent}>
      <div
        onClick={() => {
          setPopupMessageShown(false);
          onOpenChange(!chatOpened);
        }}
        className={styles.trigger}
      >
        {chatOpened && !onMobile ? (
          <div className={styles["cross-container"]}>
            <div
              className={`${styles.cross}`}
              style={{
                maskImage: `url(${baseUrl}/icons/cross.svg)`,
                WebkitMaskImage: `url(${baseUrl}/icons/cross.svg)`,
                backgroundImage: `none`,
              }}
            />
          </div>
        ) : (
          <div
            className={`${styles.logo} ${
              !!shouldLogoFallback ? styles.fallback : ""
            }`}
            style={{
              backgroundImage: `url([({trigger-icon})])`,
              maskImage: `none`,
              WebkitMaskImage: `none`,
            }}
          />
        )}
      </div>
      <div
        className={`${styles["popup-message-container"]} ${
          popupMessageShown ? "" : styles.hidden
        }`}
      >
        <div
          onClick={() => setPopupMessageShown(false)}
          className={styles.close}
        >
          <RiCloseFill color="white" size={22} />
        </div>
        <p>{dictionary.popupMessage}</p>
      </div>
    </div>
  );
};

export default ChatTrigger;
