import { getChatIdentifier } from "@/lib/key";

import useOnline from "@/hooks/useOnline";
import { voiceAssistant } from "@/lib/settings";
import { CreateMessage } from "ai";
import { useChat } from "ai/react";
import { useEffect, useState } from "react";
import AudioRecorder from "./AudioRecorder";
import styles from "./Chat.module.scss";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ChatTrigger from "./ChatTrigger";
import ChatMessages from "./messages/ChatMessages";
import MobileWrapper from "./MobileWrapper";
import Disclaimer from "./Disclaimer";
import dictionary from "@/lib/dictionary";
import { getEndpoint } from "@/lib/url";

const Chat = ({ setLocation }: { setLocation?: boolean }) => {
  const [chatIdentifier, setChatIdentifier] = useState<string>(
    getChatIdentifier()
  );

  const url = new URL(window.location.href);

  const [displayAudio, setDisplayAudio] = useState<boolean>(false);
  const [chatOpened, setChatOpened] = useState<boolean>(!!setLocation);
  const [vacancyUrl] = useState<string | null>(url.searchParams.get("elo-url"));

  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(false);

  const online = useOnline();

  const {
    messages,
    handleSubmit,
    input,
    handleInputChange,
    isLoading,
    append,
    setMessages,
  } = useChat({
    api: getEndpoint("/api/chat", {
      "domain-identifier": import.meta.env.DEV ? "dev" : "[({identifier})]",
    }),
    body: {
      chatIdentifier,
      url: vacancyUrl,
    },
  });

  useEffect(() => {
    if (vacancyUrl) {
      setChatOpened(true);
      append({
        role: "user",
        content: dictionary.automaticMessage,
      });
    }
  }, [vacancyUrl]);

  const clearChat = () => {
    setMessages([]);
    setChatIdentifier(getChatIdentifier());
  };

  return (
    <>
      {!setLocation && (
        <ChatTrigger
          chatOpened={chatOpened}
          onOpenChange={(open: boolean) => setChatOpened(open)}
        />
      )}
      <MobileWrapper
        setLocation={setLocation}
        open={chatOpened}
        setChatOpened={setChatOpened}
      >
        <div
          id={setLocation ? "" : "eloquent-chatbot-corner"}
          className={`${styles.eloquent} ${styles.container} ${
            !!setLocation ? styles["set-location"] : styles.mobile
          } ${chatOpened ? "" : styles.hidden}`}
        >
          <Disclaimer
            setShowDisclaimer={setShowDisclaimer}
            show={showDisclaimer}
          />
          {voiceAssistant && (
            <AudioRecorder
              setDisplayAudio={setDisplayAudio}
              displayAudio={online && displayAudio}
              chatIdentifier={chatIdentifier}
              setMessages={setMessages as any}
              messages={messages as CreateMessage[]}
            />
          )}
          <ChatHeader clearChat={clearChat} online={online} />
          <ChatMessages
            chatIdentifier={chatIdentifier}
            setShowDisclaimer={setShowDisclaimer}
            online={online}
            loadingResponse={
              isLoading &&
              messages.at(-1)?.role !== "assistant" &&
              !!messages.at(-1)?.content
            }
            messages={messages}
            onMessageClick={(message) =>
              append({ role: "user", content: message })
            }
          />
          <ChatFooter
            setDisplayAudio={setDisplayAudio}
            online={online}
            onChange={handleInputChange}
            onSubmit={handleSubmit}
            value={input}
            loading={isLoading}
          />
        </div>
      </MobileWrapper>
    </>
  );
};

export default Chat;
