import styles from "@/components/chat/Disclaimer.module.scss";
import dictionary from "@/lib/dictionary";
import { RiArrowLeftLine } from "@remixicon/react";

interface IProps {
  setShowDisclaimer: (show: boolean) => void;
  show: boolean;
}

const Disclaimer = ({ setShowDisclaimer, show }: IProps) => {
  return (
    <div className={styles.container} style={{ left: show ? "0" : "100%" }}>
      <div className={styles.header}>
        <RiArrowLeftLine
          className={styles.back}
          onClick={() => setShowDisclaimer(false)}
        />
        <h1>Disclaimer</h1>
      </div>
      <p>{dictionary.detailedDisclaimer}</p>
    </div>
  );
};

export default Disclaimer;
