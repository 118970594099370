import dictionary from "@/lib/dictionary";
import { shouldLogoFallback } from "@/lib/logo";
import styles from "./ChatHeader.module.scss";
import { RiChatNewLine } from "@remixicon/react";
interface IProps {
  online: boolean;
  clearChat: () => void;
}

const ChatHeader = ({ online, clearChat }: IProps) => {
  return (
    <div className={styles.header}>
      <div className={styles["info-wrapper"]}>
        <div className={styles["logo-wrapper"]}>
          <div
            className={`${styles.logo} ${
              shouldLogoFallback ? styles.fallback : ""
            }`}
            style={{
              backgroundImage: shouldLogoFallback
                ? ""
                : `url([({profile-icon})])`,
              maskImage: shouldLogoFallback ? `url([({profile-icon})])` : "",
              WebkitMaskImage: shouldLogoFallback
                ? `url([({profile-icon})])`
                : "",
            }}
          />
        </div>
        <div className={styles.info}>
          <p className={styles.name}>
            {import.meta.env.DEV ? "Eloquent" : "[({chatbot-name})]"}
          </p>
          <div className={styles.status}>
            <div
              className={`${styles["status-color"]} ${
                styles[online ? "online" : ""]
              }`}
            ></div>
            <p className={styles["status-text"]}>
              {online ? dictionary.online : dictionary.offline}
            </p>
          </div>
        </div>
      </div>
      <button
        style={{ all: "unset", display: "flex" }}
        onClick={() => clearChat()}
      >
        <RiChatNewLine size={24} color="white" />
      </button>
    </div>
  );
};

export default ChatHeader;
