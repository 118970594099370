import useAutoScroll from "@/hooks/useAutoScroll";
import dictionary from "@/lib/dictionary";
import { BAD_RESPONSE_STATUSES } from "@/lib/enums";
import { getEndpoint } from "@/lib/url";
import { cn } from "@/lib/utils";
import { RiThumbDownFill, RiThumbDownLine } from "@remixicon/react";
import { Message } from "ai";
import ky from "ky";
import { useState } from "react";

interface IProps {
  loading: boolean;
  index: number;
  chatIdentifier: string;
  messages: Message[];
}

const BadResponse = ({ loading, index, chatIdentifier, messages }: IProps) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const sendFeedback = async (message?: string) => {
    const url = getEndpoint("/api/chat/conversations/review");

    try {
      await ky.post(url, {
        json: {
          status: BAD_RESPONSE_STATUSES.BAD,
          message,
          chatIdentifier,
        },
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  useAutoScroll([isSelected, hasSubmitted]);

  const isCurrent = index === messages.length - 1;

  // Keep showing the bad response button if the user has selected it
  if (
    (loading && !isSelected) ||
    messages.length === 0 ||
    messages[index].role !== "assistant" ||
    (!isCurrent && !isSelected) ||
    index === 0
  )
    return null;

  return (
    <div className="flex flex-col gap-2 w-full">
      <div
        className="hover:cursor-pointer flex items-center gap-1"
        onClick={() => {
          setIsSelected(true);
          !isSelected && sendFeedback();
        }}
      >
        <div
          className={cn(
            "rounded-md w-fit p-1",
            isSelected && "bg-destructive-background"
          )}
        >
          {isSelected ? (
            <RiThumbDownFill className="fill-destructive" size={14} />
          ) : (
            <RiThumbDownLine size={14} />
          )}
        </div>
        <p className={cn("text-xs", isSelected && "font-semibold")}>
          {dictionary.badResponse}
        </p>
      </div>
      {hasSubmitted && (
        <p className="border text-xs text-center p-2 rounded-md">
          <span className="font-semibold">{dictionary.thanksFeedback}</span>{" "}
          {dictionary.continueText}
        </p>
      )}
      {isSelected && isCurrent && !hasSubmitted && (
        <div className="flex flex-col w-full gap-2">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendFeedback(message);
              setHasSubmitted(true);
            }}
            className="flex flex-col sm:flex-row w-full gap-2"
          >
            <input
              className="flex w-full rounded-md border font-[400] border-border px-3 py-2.5 text-base transition-colors placeholder:text-muted focus-visible:outline-none focus-visible:ring-none focus:border-accent focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 bg-background"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={dictionary.giveFeedback}
            ></input>
          </form>
          <button
            type="submit"
            disabled={!message}
            className="inline-flex p-2 px-3 items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-background-secondary/[.075] text-background-secondary font-heading hover:bg-background-secondary/10"
            onClick={() => {
              sendFeedback(message);
              setHasSubmitted(true);
            }}
          >
            {dictionary.send}
          </button>
        </div>
      )}
    </div>
  );
};

export default BadResponse;
