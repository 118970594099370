import { useEffect } from "react";

const useAutoScroll = (deps: any[]) => {
  useEffect(() => {
    const chat = document.getElementById(`eloquent-messages`);

    if (chat) chat.scrollTo(0, chat.scrollHeight);
  }, deps);
};

export default useAutoScroll;
