import { RiCloseFill, RiMicFill, RiStopFill } from "@remixicon/react";
import { CreateMessage } from "ai";
import { useEffect, useRef, useState } from "react";
import styles from "./AudioRecorder.module.scss";
import Waveform from "./Waveform";
import dictionary from "@/lib/dictionary";
import { getEndpoint } from "@/lib/url";

interface IProps {
  displayAudio: boolean;
  setDisplayAudio: (displayAudio: boolean) => void;
  messages: CreateMessage[];
  setMessages: (messages: CreateMessage[]) => void;
  chatIdentifier: string;
}

const AudioRecorder = ({
  setDisplayAudio,
  displayAudio,
  messages,
  setMessages,
  chatIdentifier,
}: IProps) => {
  const isRecordingRef = useRef<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const audioChunks = useRef<BlobPart[]>([]);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const [waiting, setWaiting] = useState<boolean>(false);
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const audioSourceRef = useRef<AudioBufferSourceNode | null>(null);
  const enabled = useRef<boolean>(displayAudio);

  useEffect(() => {
    enabled.current = displayAudio;
  }, [displayAudio]);

  const [arrayBuffer, setArrayBuffer] = useState<ArrayBuffer | null>(null);

  const startRecording = async () => {
    if (isRecordingRef.current || !enabled.current) return;
    setIsRecording(true);
    isRecordingRef.current = true;

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.current = new MediaRecorder(stream);

    mediaRecorder.current.ondataavailable = (event: BlobEvent) => {
      if (event.data.size > 0) {
        audioChunks.current.push(event.data);
      }
    };

    mediaRecorder.current.onstop = () => {
      setIsWorking(true);
      setWaiting(true);

      const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });

      sendAudioToServer(audioBlob);

      audioChunks.current = [];
    };

    mediaRecorder.current.start();
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      isRecordingRef.current = false;
    }
  };

  const toggleRecording = () => {
    if (isRecordingRef.current) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", (event) => {
      if (
        event.code === "Space" &&
        !isWorking &&
        !isRecordingRef.current &&
        enabled.current
      )
        toggleRecording();
    });

    return () => {
      window.removeEventListener("keydown", (event) => {
        if (
          event.code === "Space" &&
          !isWorking &&
          !isRecordingRef.current &&
          enabled.current
        )
          toggleRecording();
      });
    };
  }, []);

  const sendAudioToServer = async (audioBlob: Blob) => {
    if (!enabled.current) return;
    const formData = new FormData();
    formData.append("audio", audioBlob, "recording.wav");
    formData.append("messages", JSON.stringify(messages));

    try {
      const response = await fetch(
        getEndpoint("/api/chat/speech", {
          "domain-identifier": import.meta.env.DEV ? "dev" : "[({identifier})]",
        }),
        {
          headers: {
            "chat-identifier": chatIdentifier,
          },
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Error during audio upload");
      }

      const messagesResponse = await fetch(
        getEndpoint("/api/chat/speech", {
          "domain-identifier": import.meta.env.DEV ? "dev" : "[({identifier})]",
        }),
        {
          headers: {
            "chat-identifier": chatIdentifier,
          },
        }
      );

      if (messagesResponse.ok) {
        const { messages } = await messagesResponse.json();

        setMessages([...messages]);
      }

      const buffer = await response.arrayBuffer();

      setWaiting(false);

      setArrayBuffer(buffer);

      const audioContext = new AudioContext();
      const audioBuffer = await audioContext.decodeAudioData(buffer.slice(0));
      const audioSource = audioContext.createBufferSource();
      audioSource.buffer = audioBuffer;
      audioSource.connect(audioContext.destination);
      audioSource.start();

      audioSourceRef.current = audioSource;

      audioSource.onended = () => {
        setArrayBuffer(null);
        setIsWorking(false);
      };
    } catch (error: any) {
      setArrayBuffer(null);
      setIsWorking(false);
      console.error("Error during audio upload:", error);
    }
  };

  const interrupt = () => {
    stopRecording();
    setWaiting(false);
    setArrayBuffer(null);
    setIsWorking(false);
    audioSourceRef.current?.stop();
  };

  return (
    <div className={`${styles.container} ${displayAudio ? styles.open : ""}`}>
      <div className={styles.gradient}>
        <Waveform source={arrayBuffer} />
        <div className={styles["buttons-container"]}>
          <div className={`${styles["icon-small"]} ${styles.hidden}`}></div>
          <div className={styles.action} style={{ gap: waiting ? "0px" : "" }}>
            <div className={styles.text}>
              <p
                style={{
                  opacity: !waiting && !isWorking && !isRecording ? "1" : "0",
                }}
              >
                {dictionary.pushToTalk}
              </p>
              <div>
                <Waveform mic={isRecording} size={5} disableLoading />
              </div>
              <p style={{ opacity: waiting ? "1" : "0" }}>
                {dictionary.loading}...
              </p>
              <p style={{ opacity: isWorking && !waiting ? "1" : "0" }}>
                {dictionary.tapToInterrupt}
              </p>
            </div>
            <div
              className={`${styles["icon-big"]} ${
                isWorking ? styles.working : ""
              } ${waiting ? styles.waiting : ""} ${
                isRecording ? styles.recording : ""
              }`}
              onClick={!isWorking ? toggleRecording : interrupt}
            >
              <RiMicFill opacity={isWorking && !waiting ? "0" : "1"} />
              <RiStopFill
                size={30}
                opacity={isWorking && !waiting ? "1" : "0"}
              />
            </div>
          </div>
          <div
            style={{ zIndex: 10 }}
            onClick={() => {
              setDisplayAudio(false);
            }}
          >
            <div
              className={`${styles["icon-small"]} ${
                isWorking ? styles.hidden : ""
              }`}
            >
              <RiCloseFill />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioRecorder;
