export const setLocation: true | false | string = import.meta.env.DEV
  ? import.meta.env.VITE_SET_LOCATION
    ? true
    : false
  : import.meta.env.THIS_WILL_BE_FALSE
  ? ""
  : "[({set-location})]";

export const manualRendering: true | false | string = import.meta.env.DEV
  ? import.meta.env.VITE_MANUAL_RENDERING
    ? true
    : false
  : import.meta.env.THIS_WILL_BE_FALSE
  ? ""
  : "[({manual-rendering})]";

export const voiceAssistant: true | false | string = import.meta.env.DEV
  ? import.meta.env.VITE_VOICE_ASSISTANT
    ? true
    : false
  : import.meta.env.THIS_WILL_BE_FALSE
  ? ""
  : "[({voice-assistant})]";

export const agentMode = import.meta.env.DEV
  ? import.meta.env.VITE_AGENT_MODE
  : "[({agent-mode})]";

export const whiteLabeling: true | false | string = import.meta.env.DEV
  ? import.meta.env.VITE_WHITE_LABELING
    ? true
    : false
  : import.meta.env.THIS_WILL_BE_FALSE
  ? ""
  : "[({white-labeling-name})]";
