import { useEffect, useState } from "react";

const useDebounce = (initialValue?: string, delay?: number) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [debouncedValue, setDebouncedValue] = useState<string>(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return { debouncedValue, value, setValue };
};

export default useDebounce;
