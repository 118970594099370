import { useRef } from "react";

export interface IResult {
  id: number;
  url: string;
  title: string | null;
  og_title: string | null;
  og_description: string | null;
  og_image: string | null;
  og_type: string | null;
  favicon: string | null;
  similarity: number;
}

interface IProps {
  result: IResult;
}

const DESCRIPTION_LIMIT = 100;

const Result = ({ result }: IProps) => {
  const ref = useRef<HTMLAnchorElement>(null);

  const limitText = (text: string) => {
    return text.length > DESCRIPTION_LIMIT
      ? text.slice(0, DESCRIPTION_LIMIT) + "..."
      : text;
  };

  return (
    <div>
      <a
        href={result.url}
        target="_blank"
        ref={ref}
        className="bg-muted-secondary border transition-all rounded-xl duration-500 overflow-hidden group/product hover:cursor-pointer grid grid-cols-[1fr_200px] p-2"
      >
        <div className="p-3 flex flex-col gap-1">
          <p className="font-heading font-semibold">
            {result.og_title || result.title}
          </p>
          {result.og_description && (
            <p className="text-sm">{limitText(result.og_description)}</p>
          )}
        </div>
        {result.og_image && (
          <div className="w-44 aspect-[1200/630] rounded-md overflow-hidden place-self-center">
            <img
              src={result.og_image || ""}
              alt={""}
              className="w-full object-cover object-center bg-cover bg-center transition-transform"
            />
          </div>
        )}
      </a>
    </div>
  );
};
export default Result;
