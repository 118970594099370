export const baseUrl = import.meta.env.DEV
  ? "http://localhost:5173"
  : "[({snippet-url})]";

const localPort = import.meta.env.VITE_ELOQUENT_PORT ?? 3000;

interface IParams {
  [key: string]: string;
}

export const getEndpoint = (endpoint: string, params?: IParams) => {
  const stringedParams = new URLSearchParams(params).toString();

  return import.meta.env.DEV
    ? `http://localhost:${localPort}${endpoint}?${stringedParams}`
    : `[({production-url})]${endpoint}?${stringedParams}`;
};
