import { CreateMessage } from "ai/react";
import styles from "./ChatMessages.module.scss";
import Markdown from "react-markdown";

interface IProps {
  children: React.ReactNode;
  message: CreateMessage;
}

const AssistantMessage = ({ children, message }: IProps) => {
  return (
    <div className="flex flex-col gap-2">
      <div className={styles["assistant-message"]}>
        <Markdown
          components={{
            a: ({ href, children }) => (
              <a href={href} target="_blank">
                {children}
              </a>
            ),
          }}
        >
          {message.content}
        </Markdown>
      </div>
      {children}
    </div>
  );
};

export default AssistantMessage;
