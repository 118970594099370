import Chat from "./components/chat/Chat";
import MagicSearch from "./components/magicSearch/MagicSearch";
import WebSearch from "./components/webSearch/WebSearch";
import "./index.css";
import "./tailwind.css";

const App = ({
  setLocation,
  mode = "chat",
}: {
  setLocation?: boolean;
  mode: "chat" | "magic-search" | "products" | "web-search";
}) => {
  const component = (mode: string) => {
    switch (mode) {
      case "magic-search":
        return <MagicSearch />;
      case "web-search":
        return <WebSearch />;
      default:
        return <Chat setLocation={setLocation} />;
    }
  };

  return component(mode);
};

export default App;
